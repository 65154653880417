body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

#telegram-login-a911wincobot {
  width: 100% !important;
}

html {
  height: -webkit-fill-available !important;
}

.webkit-height {
  min-height: -webkit-fill-available;
  height: 100dvh;
}

.absolute-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text-overflow {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.no-scrollbar::-webkit-scrollbar {
  display: none !important;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
