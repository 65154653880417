body {
  margin: 0;
  font-family: Inter, -apple-system, Framedcn, Helvetica Neue, Condensed,
    DisplayRegular, Helvetica, Arial, PingFang SC, Hiragino Sans GB,
    WenQuanYi Micro Hei, Microsoft Yahei, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-user-select: none;  
  -khtml-user-select: none;  
  -moz-user-select: none;  
  -ms-user-select: none; 
  user-select: none;  */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#chakra-toast-manager-bottom {
  z-index: 99999 !important;
}
